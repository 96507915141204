(function () {
	'use strict';
	angular.module('eventvods')
		.controller('ContactController', ['API_BASE_URL', '$http', '$timeout',
		 function (API, $http) {
			var vm = this;
			vm.data = {
				reason: ""
			};

			vm.reasons = [
				'REPORT A BUG',
				'REPORT A BROKEN LINK/VIDEO',
				'FEATURE SUGGESTION',
				'GENERAL ENQUIRY',
				'OTHER',
			];

			vm.send = function(form){
				$('#contactForm input, #contactForm select, #contactForm textarea').addClass('ng-touched');
				if (form.$valid) {
					$http.post(API + "/mail", vm.data)
					.then(function (res) {

						vm.success = true;
						$timeout(function(){
							delete vm.success;
							form.$setUntouched();
							form.$setPristine();
							vm.data = {
								reason: ""
							};
						}, 5000);
					});
				}
			}
		}]);
}());
